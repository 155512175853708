import request from '@/util/request'
// 查询手机报警规则列表
export function listPhonealarmrules(query) {
  return request({
    url: '/phonealarmrules/list',
    method: 'get',
    params: query
  })
}

// 查询手机报警规则详细
export function getPhonealarmrules(id) {
  return request({
    url: '/phonealarmrules/' + id,
    method: 'get'
  })
}

// 新增手机报警规则
export function addPhonealarmrules(data) {
  return request({
    url: '/phonealarmrules',
    method: 'post',
    data: data
  })
}

// 修改手机报警规则
export function updatePhonealarmrules(data) {
  return request({
    url: '/phonealarmrules',
    method: 'put',
    data: data
  })
}

// 删除手机报警规则
export function delPhonealarmrules(id) {
  return request({
    url: '/phonealarmrules/' + id,
    method: 'delete'
  })
}
