<template>
  <div>
  <van-list
      v-model="loading"
      :finished="true"
      @load="getData"
  >
    <van-swipe-cell v-for="item in list" :key="item.id" >
      <van-cell  is-link :title="item.name" :to="{name:'phonealarmrules/edit',params:{id:item.id}}" />
      <template #right>
        <div style="padding-right:5px;width: 100%;height: 100%;display: flex;align-items:center; justify-content:center;">
          <van-button icon="delete-o" type="danger" plain @click="del(item.id)" size="small" />
        </div>

      </template>
    </van-swipe-cell>
  </van-list>
    <div style="width: 100%;text-align: center">
        <van-button plain type="info" @click="add">添加</van-button>
    </div>
  </div>
</template>

<script>
import {List, Cell, Icon, Button, SwipeCell, Notify} from 'vant';
import {listPhonealarmrules,delPhonealarmrules} from "../../api/phonealarmrules";
export default {
  components:{
    [List.name]:List,
    [Cell.name]:Cell,
    [Icon.name]:Icon,
    [Button.name]:Button,
    [SwipeCell.name]:SwipeCell,
  },
  name: "index",
  data(){
    return {
      loading:true,
      list:[]
    }
  },
  methods:{
    getData(){
      listPhonealarmrules().then(res => {
        this.loading = false;
        this.list = res.rows;
        window.console.log(this.list)
      })
    },
    del(id){
      delPhonealarmrules(id).then(() => {
        Notify({ type: 'success', message: this.$t('数据删除成功'),duration:500 })
        this.getData();
      })
    },
    add(){
      this.$router.push({
        name:"phonealarmrules/edit"
      })
    }
  },
  created() {
    this.getData();
  },
  activated(){
    this.getData();
  }
}
</script>

<style scoped>

</style>
